.drawer {
  z-index: 5000;
  display: flex;

  .container {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  .preview {
    display: flex;
    flex: 2;
    height: 100%;
  }
}
